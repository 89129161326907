.color_change {
    text-align: center;
    color: #DF9228;
    font-family: 'Righteous', serif, system-ui;
}

.h4_change {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.h5_change {
    text-align: center;
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
}

.menu {
    margin-top: 3%;
    margin-bottom: 3%;
}