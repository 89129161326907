.color_change {
    text-align: center;
    color: #DF9228;
}

.h4_change {
    text-align: center;
}

.h5_change {
    text-align: center;
}


.h5_text {
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    word-wrap: break-word;
}

.line_through {
    border-style: solid;
    border-bottom-width: 1px;
    border-color: #DF9228;;
    margin-top: 8px;
    width: 100%;
    box-sizing: content-box;
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    unicode-bidi: isolate;
    overflow: hidden;
}

.image_gallery {
    margin-top: 3%;
    margin-bottom: 3%;
}