.main_div {
    display: flex;
    padding: 100px;
    background-color: black;
    height: 100%;
}

/* .main_image {
    flex: 1;
    justify-content: center;
    align-items: center;
    justify-content: center;
} */

.image {
    width: 100%;
    height: auto;  /* auto for image   */
}

.about_text {
    margin-bottom: 50px;
    font-family: "PT Serif", serif;
}

.about_name {
    font-family: "Montserrat", sans-serif;
    justify-content: center;
    text-align: center;
    margin: 20px;
}

.main_text {
    color: white;
    font-family: 'Righteous', serif, system-ui;
    /* font-family: "PT Serif", serif; */
    position: relative;
    /* top: 50%;
    transform: translateY(-50%);
    margin-left: 50px; */
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 30%;
    margin-bottom: 60px;
}

.main_button {
    margin-top: 20px;
    margin-bottom: 20px;
    top: 35%;
    transform: translateY(-50%);
    margin-left: 35px;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #DF9228;
    color: white;
}

#h5 {
    margin-top: 30px;
}

#button {
    color: white;
    background: #DF9228;
}

.happy_div {
    background: rgb(246, 246, 246);
    justify-content: center;
    align-items: center;
    text-align: center;
}

.happy_hour_image {
    margin-top: 50px;
    width: 50%;
    height: auto;
}

.happy_hour_text {
    font-family: "PT Serif", serif;
    margin: 50px;
}

.contact_div {
    background: rgb(246, 246, 246);
    margin-top: 50px;
}

.h1_home {
    font-family: 'Righteous', serif, system-ui;
    color: #DF9228;
    text-align: center;
    margin: 50px;
}

.contact_text {
    font-family: "PT Serif", serif;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 50px;
    margin-bottom: 150px;
}

.follow_us_link {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.fa {
    padding: 20px;
    font-size: 30px;
    /* width: 50px; */
    text-decoration: none;
}

.fa-facebook {
    background: #3B5998;
    color: white;
}

.event_div {
    background: rgb(246, 246, 246);
}